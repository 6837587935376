// @flow
import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
// 計測期間になったのでパターンBの画像と入れ替える（最終的に数値が良かった方を残す）
// import FdlImageA from '../../assets/images/views/layouts/header_menu_fdl/pattern_a.png';
import FdlImageB from '../../assets/images/views/layouts/header_menu_fdl/pattern_b.png';
import styled from 'styled-components';
import { BuildSubDomainURL } from '../../lib/BuildSubDomainURL';
import type { Node } from 'react';

type Props = {
  userId: ?number,
  path: string,
  fdl: string,
  isOpen: boolean,
  onClickLink: (e: any, path: string) => void,
  onClose: () => void,
};

export default function HeaderMobileMenuModal({
  userId,
  path,
  fdl,
  isOpen,
  onClickLink,
  onClose,
}: Props): Node {
  const isSpaPath = path === '/' || path.includes('/my');
  function onClickFDL() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'Download',
      event_label: 'Menu-FDL',
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>メニュー</ModalHeader>
      {/* <a href={fdl} onClick={onClickFDL.bind(this)}>
        <img src={FdlImageA} className="w-100" />
      </a> */}
      <ModalBody className="mb-60">
        {/* 計測期間になったら上のFDLと入れ替える */}
        <a href={fdl} onClick={onClickFDL.bind(this)} className="mb-10">
          <img src={FdlImageB} className="w-100" />
        </a>
        <ListGroup className="mb-4">
          <StyledListGroupItem>
            <a href="/catches">みんなの釣果</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <a href="/areas">エリアから探す</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <a href="/regions">地方・都道府県から探す</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <a href={BuildSubDomainURL('ships')}>釣船予約</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <a href="/fishes">魚種から探す</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <a href={BuildSubDomainURL('tacklebox')}>タックルボックス</a>
          </StyledListGroupItem>
          <StyledListGroupItem>
            {userId === null && <a href="/users/sign_in">ログイン</a>}
            {userId !== null && <a href="/users/sign_out">ログアウト</a>}
          </StyledListGroupItem>
        </ListGroup>
        {userId !== null && isSpaPath && (
          <ListGroup>
            <StyledListGroupItem>
              <a href="/my">タイムライン</a>
            </StyledListGroupItem>
            {userId !== null && (
              <StyledListGroupItem>
                <a href={`/users/${String(userId)}`}>プロフィール</a>
              </StyledListGroupItem>
            )}
            <StyledListGroupItem>
              <a href={BuildSubDomainURL('tacklebox', 'favorites')}>
                お気に入り（タックルボックス）
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/followings')}>
                フォロー中のユーザー
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/users/blocks')}>
                ブロック中のユーザー
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/ships')}>
                フォロー中の船宿・釣船
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/areas')}>
                お気に入りのエリア
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/fields')}>
                フィールド
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/rods')}>
                ロッド
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/reels')}>
                リール
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/lures')}>
                ルアー
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="" onClick={e => onClickLink(e, '/my/product_reviews')}>
                レビュー
              </a>
            </StyledListGroupItem>
          </ListGroup>
        )}
        {userId !== null && !isSpaPath && (
          <ListGroup>
            <StyledListGroupItem>
              <a href="/my">タイムライン</a>
            </StyledListGroupItem>
            {userId !== null && (
              <StyledListGroupItem>
                <a href={`/users/${String(userId)}`}>プロフィール</a>
              </StyledListGroupItem>
            )}
            <StyledListGroupItem>
              <a href="/my/followings">フォロー中のユーザー</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/ships">フォロー中の船宿・釣船</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href={BuildSubDomainURL('tacklebox', 'favorites')}>
                お気に入り（タックルボックス）
              </a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/areas">お気に入りのエリア</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/fields">フィールド</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/rods">ロッド</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/reels">リール</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/lures">ルアー</a>
            </StyledListGroupItem>
            <StyledListGroupItem>
              <a href="/my/product_reviews">レビュー</a>
            </StyledListGroupItem>
          </ListGroup>
        )}
        <div className="mt-20 mb-60">
          <a
            href="/contact?contact_category_id=8"
            targer="_blank"
            rel="noopener noreferrer"
          >
            運営へフィードバックを送る
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
}

const StyledListGroupItem = styled(ListGroupItem)`
  position: relative;

  &::before {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    content: '\f3d3';
    color: #2b2b2b;
    font-family: 'Ionicons';
    font-size: 16px;
    font-weight: 400;
    padding-right: 1rem;
  }

  a {
    color: #2b2b2b;
  }
`;
