// @flow
import moment from 'moment';
import type { Result } from '../results/Result';
import type { Voter } from '../results/Voter';
import type { Area } from '../areas/Area';
import type { Product } from '../products/Product';
import type { Review } from '../products/reviews/Review';

export const KEY_RESULT_CREATED = 10;
export const KEY_RESULT_UPDATED = 11;
export const KEY_RESULT_DESTROYED = 12;
export const KEY_RESULT_LIKED = 13;
export const KEY_RESULT_UNLIKED = 14;
export const KEY_RESULT_COMMENTED = 15;
export const KEY_PRODUCT_REVIEW_CREATED = 20;
export const KEY_PRODUCT_REVIEW_UPDATED = 21;
export const KEY_PRODUCT_REVIEW_DESTROYED = 22;
export const KEY_PRODUCT_REVIEW_LIKED = 23;
export const KEY_PRODUCT_REVIEW_UNLIKED = 24;
export const KEY_PRODUCT_REVIEW_COMMENTED = 25;

export type Timeline = {
  id: number,
  key: number,
  updated_at: moment,
  owner: {
    user: ?{
      id: number,
      name: string,
      is_official: boolean,
      thumb_url: string,
      is_meister: boolean,
      is_ship_captain: boolean,
    },
    area: ?Area,
    product: ?Product,
  },
  result: ?Result,
  product_review: ?Review,
  voters: Array<Voter>,
};
