// @flow
import AxiosClient from '../AxiosClient';
import ProductHelper from '../../helpers/ProductHelper';

import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { MyReel } from './MyReel';
import type { MyReelForm } from './MyReelForm';

export default class MyReelClient extends AxiosClient {
  fetchAll(account: Account): AxiosPromise<Array<MyReel>> {
    return this.buildTokenAuthClient(account).get(`/my/tackles/reels.json`);
  }

  fetchMyReel(id: number, account: Account): AxiosPromise<MyReel> {
    return this.buildTokenAuthClient(account).get(
      `/my/tackles/reels/${id}.json`
    );
  }

  create(
    form: MyReelForm,
    account: Account,
    token: string
  ): AxiosPromise<MyReel> {
    return this.buildTokenAuthClient(account, token).post(
      `/my/tackles/reels.json`,
      this._buildFormData(form)
    );
  }

  update(
    id: number,
    form: MyReelForm,
    account: Account,
    token: string
  ): AxiosPromise<MyReel> {
    return this.buildTokenAuthClient(account, token).patch(
      `/my/tackles/reels/${id}.json`,
      this._buildFormData(form)
    );
  }

  delete(id: number, account: Account): AxiosPromise<any> {
    return this.buildTokenAuthClient(account).delete(
      `/my/tackles/reels/${id}.json`
    );
  }

  _buildFormData(form: MyReelForm) {
    const data = new FormData();
    const {
      maker,
      series,
      reel,
      name,
      memo,
      get_date,
      lost_date,
      reel_thread_date,
      reel_length,
      line_maker,
      line_series,
      line,
      line_name,
    } = form;

    data.append(
      'mytackle_reel[reel_maker_id]',
      maker ? maker.id.toString() : ''
    );
    data.append(
      'mytackle_reel[reel_series_id]',
      series ? series.id.toString() : ''
    );
    data.append(
      'mytackle_reel[reel_product_id]',
      reel ? reel.id.toString() : ''
    );
    data.append(
      'mytackle_reel[reelnm]',
      ProductHelper.enableFreeInputReel(form) ? name || '' : ''
    );
    data.append('mytackle_reel[memo]', memo || '');
    data.append(
      'mytackle_reel[getdate]',
      get_date ? get_date.format('YYYYMMDD') : ''
    );
    data.append(
      'mytackle_reel[lostdate]',
      lost_date ? lost_date.format('YYYYMMDD') : ''
    );
    data.append(
      'mytackle_reel[reelthreaddate]',
      reel_thread_date ? reel_thread_date.format('YYYYMMDD') : ''
    );
    data.append(
      'mytackle_reel[reellength]',
      reel_length ? reel_length.toString() : ''
    );
    data.append(
      'mytackle_reel[line_maker_id]',
      line_maker ? line_maker.id.toString() : ''
    );
    data.append(
      'mytackle_reel[line_series_id]',
      line_series ? line_series.id.toString() : ''
    );
    data.append(
      'mytackle_reel[line_product_id]',
      line ? line.id.toString() : ''
    );
    data.append(
      'mytackle_reel[line]',
      ProductHelper.enableFreeInputLine(form) ? line_name || '' : ''
    );

    form.images.forEach((image, i) => {
      data.append(
        `mytackle_reel[images_attributes][${i}][_destroy]`,
        image._destroy.toString()
      );

      if (image.id != null) {
        data.append(
          `mytackle_reel[images_attributes][${i}][id]`,
          image.id.toString()
        );
      }
      if (image.image != null) {
        data.append(
          `mytackle_reel[images_attributes][${i}][image]`,
          image.image
        );
      }
    });

    return data;
  }
}
