// @flow
import React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { Timeline } from '../../../models/timelines/Timeline';
import Loader from '../../commons/Loader';
import TimelineClient from '../../../models/timelines/TimelineClient';
import ResultTimelineItem from '../../results/ResultTimelineItem';
import ReviewTimelineItem from '../../products/reviews/ReviewTimelineItem';
import {
  KEY_RESULT_COMMENTED,
  KEY_RESULT_CREATED,
  KEY_RESULT_LIKED,
  KEY_PRODUCT_REVIEW_CREATED,
} from '../../../models/timelines/Timeline';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
};

type State = {
  timelines: Array<Timeline>,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
};

class IndexView extends React.Component<Props, State> {
  timelineClient = new TimelineClient();
  canCloseWeathersDropdown = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      timelines: [],
      isLoading: true,
      page: 1,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    this.fetchTimelines();
  }

  fetchTimelines() {
    const { page } = this.state;

    this.timelineClient
      .fetchAll(page)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          timelines: [...this.state.timelines, ...response.data].filter(
            (t, i, self) => self.map(t2 => t2.id).indexOf(t.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onLoadMore() {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchTimelines()
      );
    }
  }

  render() {
    const { account } = this.props;
    const { timelines, isLoading } = this.state;

    if (timelines.length > 0) {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => this.onLoadMore()}
          hasMore={this.state.hasNextPage}
          loader={<Loader key={0} />}
        >
          {timelines.map(t => {
            if (
              (t.key === KEY_RESULT_CREATED ||
                t.key === KEY_RESULT_LIKED ||
                t.key === KEY_RESULT_COMMENTED) &&
              t.result != null
            ) {
              return (
                <ResultTimelineItem
                  key={t.id}
                  result={t.result}
                  timeline={t}
                  token={csrfToken()}
                />
              );
            } else if (
              t.key === KEY_PRODUCT_REVIEW_CREATED &&
              t.product_review != null
            ) {
              return (
                <ReviewTimelineItem
                  key={t.id}
                  review={t.product_review}
                  timeline={t}
                  account={account}
                  token={csrfToken()}
                />
              );
            } else {
              return null;
            }
          })}
        </InfiniteScroll>
      );
    } else {
      if (isLoading) {
        return <Loader />;
      } else {
        return (
          <div>お気に入りのエリアや興味のある釣り人をフォローしましょう</div>
        );
      }
    }
  }
}

export default connect(state => ({ account: state.account }))(IndexView);
