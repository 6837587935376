// @flow
import React from 'react';
import type { Review } from '../../../models/products/reviews/Review';
import type { Voter } from '../../../models/products/reviews/Voter';

type Props = {
  review: Review,
  voters: Array<Voter>,
};

export default function Voters(props: Props) {
  const { review, voters } = props;

  if (voters.length === 0) {
    return <div>{`${review.cached_votes_up}件`}</div>;
  } else {
    if (review.cached_votes_up === voters.length && voters.length < 3) {
      return (
        <div>
          {voters.map(voter => (
            <a
              href={`/users/${voter.id}`}
              className="mr-2"
              key={voter.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {voter.name}
            </a>
          ))}
        </div>
      );
    } else {
      if (voters.length > 1) {
        return (
          <div>
            <a
              href={`/users/${voters[0].id}`}
              className="mr-2"
              key={voters[0].id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {voters[0].name}
            </a>
            <a
              href={`/users/${voters[1].id}`}
              className="mr-2"
              key={voters[1].id}
            >
              {voters[1].name}
            </a>
            <span>{`他${review.cached_votes_up - 2}件`}</span>
          </div>
        );
      } else {
        return (
          <div>
            <a
              href={`/users/${voters[0].id}`}
              className="mr-2"
              key={voters[0].id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {voters[0].name}
            </a>
            <span>{`他${review.cached_votes_up - 1}件`}</span>
          </div>
        );
      }
    }
  }
}
