// @flow
import * as axios from 'axios';
import type { Axios } from 'axios';
import type { Account } from './accounts/Account';

export default class AxiosClient {
  buildClient(token?: string): Axios {
    let headers = {
      'Content-Type': 'application/json',
    };

    if (token != null) {
      headers = {
        ...headers,
        'X-CSRF-Token': token,
      };
    }

    return axios.create({
      timeout: 60000,
      headers: headers,
    });
  }

  buildTokenAuthClient(account: Account, token?: string): Axios {
    let headers = {
      'Content-Type': 'application/json',
      'X-Account-Email': account.email,
      'X-Account-Token': account.authentication_token,
    };

    if (token != null) {
      headers = {
        ...headers,
        'X-CSRF-Token': token,
      };
    }

    return axios.create({
      baseURL: '/api/v2',
      timeout: 60000,
      headers: headers,
    });
  }
}
