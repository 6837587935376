// @flow
import React from 'react';
import type { Node } from 'react';
import type { Result } from '../../models/results/Result';
import moment from 'moment';
import type { Timeline } from '../../models/timelines/Timeline';
import {
  KEY_RESULT_COMMENTED,
  KEY_RESULT_CREATED,
  KEY_RESULT_LIKED,
} from '../../models/timelines/Timeline';
import ResultSocialComp from './ResultSocialComp';
import Comments from './comments/Comments';
import { default as UserNoImage } from '../../assets/images/views/users/no-image.png';

type Props = {
  result: Result,
  timeline: Timeline,
  token: string,
};

type State = {
  result: Result,
  commentsVisible: boolean,
  focusCommentForm: boolean,
};

export default class ResultTimelineItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      result: props.result,
      commentsVisible: false,
      focusCommentForm: false,
    };
  }

  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToCatch',
      event_label: 'result_timeline_item',
    });
  }

  renderArea(result: Result): Node {
    if (result.area != null) {
      return (
        <div className="c-blue">
          <a
            href={`/areas/${result.area.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-map" /> {result.area.name}
          </a>
        </div>
      );
    } else if (result.postal_code != null) {
      return (
        <div className="text-muted">
          <i className="fa fa-map" /> {result.postal_code.name}
        </div>
      );
    } else {
      return (
        <div className="text-muted">
          <i className="fa fa-map" /> 未選択
        </div>
      );
    }
  }

  render(): Node {
    const { timeline, token } = this.props;
    const { result, commentsVisible, focusCommentForm } = this.state;
    const { owner } = timeline;

    return (
      <div className="results timeline card mb-60">
        <div className="card-header">
          {((timeline.key === KEY_RESULT_CREATED &&
            owner.area == null &&
            owner.product == null) ||
            timeline.key === KEY_RESULT_LIKED) && (
            <div className="media">
              <div className="pull-left">
                <a
                  href={`/users/${result.user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={result.user.thumb_url}
                    className="rounded-circle"
                    width={60}
                    alt="Image Not Found"
                    onError={e => (e.target.src = UserNoImage)}
                  />
                </a>
              </div>
              <div className="media-body">
                <h6 className="media-heading">
                  <a
                    href={`/users/${result.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {result.user.name}
                  </a>
                  {result.user.is_official && (
                    <i className="fa fa-check-square text-approved ml-5" />
                  )}
                </h6>
                <div>
                  釣果日: {moment(result.caught_at).format('YYYY.MM.DD  HH:mm')}
                </div>
              </div>
            </div>
          )}
          {timeline.key === KEY_RESULT_COMMENTED && owner.user != null && (
            <div className="media ">
              <div className="pull-left">
                <a
                  href={`/users/${owner.user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={owner.user.thumb_url}
                    className="rounded-circle"
                    width={60}
                    alt="Image Not Found"
                    onError={e => (e.target.src = UserNoImage)}
                  />
                </a>
              </div>
              <div className="media-body">
                <h6 className="media-heading">
                  <a
                    href={`/users/${owner.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {owner.user.name}
                  </a>
                  {owner.user.is_official && (
                    <i className="fa fa-check-square text-approved ml-5" />
                  )}
                </h6>
                <div>釣果にコメントしました</div>
                <small className="date">
                  {timeline.updated_at.format('YYYY年MM月DD日  HH:mm')}
                </small>
              </div>
            </div>
          )}
          {owner.area != null && (
            <div className="media ">
              {owner.area.latest_result_square_photo_url != null && (
                <div className="pull-left">
                  <a
                    href={`/areas/${owner.area.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={owner.area.latest_result_square_photo_url}
                      className="rounded-circle"
                      width={60}
                      alt="Image Not Found"
                      onError={e => (e.target.src = 'images/noimage.png')}
                    />
                  </a>
                </div>
              )}
              <div className="media-body">
                <h6 className="media-heading">
                  <a
                    href={`/areas/${owner.area.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {owner.area.name}
                  </a>
                </h6>
                <div>
                  釣果日: {moment(result.caught_at).format('YYYY.MM.DD  HH:mm')}
                </div>
              </div>
            </div>
          )}
          {owner.product != null && (
            <div className="media d-flex align-items-center">
              {owner.product.thumbnails != null && (
                <div className="pull-left">
                  <a
                    href={`/tackles/${owner.product.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={owner.product.thumbnails[0]}
                      width={60}
                      alt="Image Not Found"
                      onError={e => (e.target.src = 'images/noimage.png')}
                    />
                  </a>
                </div>
              )}
              <div className="media-body">
                <h6 className="media-heading">
                  <a
                    href={`/tackles/${owner.product.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {owner.product.full_name}
                  </a>
                </h6>
                <div>
                  釣果日: {moment(result.caught_at).format('YYYY.MM.DD  HH:mm')}
                </div>
              </div>
            </div>
          )}
        </div>
        <a
          href={`/catches/${result.id}`}
          onClick={this.onClick.bind(this)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="thumbnail">
            <img
              src={result.thumb_url}
              alt={result.fish != null ? result.fish.name : ''}
            />
          </div>
        </a>
        <div className="card-body">
          {timeline.key === KEY_RESULT_COMMENTED && owner.user != null && (
            <div className="media mt-10 mb-5">
              <div className="pull-left">
                <a
                  href={`/users/${result.user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={result.user.thumb_url}
                    className="rounded-circle"
                    width={40}
                    alt="Image Not Found"
                    onError={e => (e.target.src = UserNoImage)}
                  />
                </a>
              </div>
              <div className="media-body">
                <h6 className="media-heading">
                  <a
                    href={`/users/${result.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {result.user.name}
                  </a>
                  {result.user.is_official && (
                    <i className="fa fa-check-square text-approved ml-5" />
                  )}
                </h6>
                <div>
                  釣果日: {moment(result.caught_at).format('YYYY.MM.DD  HH:mm')}
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between fish border-bottom pt-10 pb-10">
            <div>
              {result.fish != null ? (
                <a
                  href={`/fishes/${result.fish.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-app" /> {result.fish.name}
                </a>
              ) : (
                <span className="text-muted">
                  <i className="icon-app" /> 未選択
                </span>
              )}
            </div>
            {this.renderArea(result)}
          </div>
          {(result.publish_comment != null || result.lure_details !== '') && (
            <div className="comment text-muted pt-2">
              {result.publish_comment != null ? result.publish_comment : ''}
              {result.lure_details}
            </div>
          )}
          <ResultSocialComp
            result={result}
            voters={timeline.voters}
            voteAccessible={true}
            isSignedIn={true}
            token={token}
            onToggleVoted={(voted, cached_votes_up) => {
              this.setState({
                result: {
                  ...result,
                  voted: voted,
                  cached_votes_up: cached_votes_up,
                },
              });
            }}
            onClickComments={focusedInput => {
              this.setState({
                commentsVisible: true,
                focusCommentForm: focusedInput,
              });
            }}
          />
        </div>
        {commentsVisible && (
          <div className="card-footer">
            <Comments
              result={result}
              token={token}
              focusForm={focusCommentForm}
              userSignedIn={true}
            />
          </div>
        )}
      </div>
    );
  }
}
