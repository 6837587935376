// @flow
import React from 'react';
import { BuildSubDomainURL } from '../../lib/BuildSubDomainURL';
import AuthModal from '../layouts/AuthModal';

type Props = {
  active: string,
  userName: ?string,
};

export default class HeaderMenu extends React.PureComponent<Props> {
  render(): React$Node {
    const { active, userName } = this.props;

    return (
      <ul className="navbar-nav ml-auto">
        {userName === null && (
          <li className="nav-item">
            <span className="nav-link">
              <AuthModal />
            </span>
          </li>
        )}
        <li className="nav-item">
          <a
            href="/catches"
            className={active === 'results' ? 'nav-link active' : 'nav-link'}
          >
            みんなの釣果
          </a>
        </li>
        <li className="nav-item dropdown">
          <a
            href="#"
            className={
              active === 'areas' || active === 'fishes'
                ? 'nav-link dropdown-toggle active'
                : 'nav-link dropdown-toggle'
            }
            data-toggle="dropdown"
          >
            カテゴリから探す
            <b className="caret" />
          </a>
          <ul className="dropdown-menu ">
            <li>
              <a href="/areas" className="dropdown-item">
                エリアから探す
              </a>
            </li>
            <li>
              <a href="/regions" className="dropdown-item">
                地方・都道府県から探す
              </a>
            </li>
            <li>
              <a href={BuildSubDomainURL('ships')} className="dropdown-item">
                釣船予約
              </a>
            </li>
            <li>
              <a href="/fishes" className="dropdown-item">
                魚種から探す
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a
            href="#"
            className={
              active === 'products/pages' ||
              active === 'products/rods' ||
              active === 'products/reels' ||
              active === 'my/products'
                ? 'nav-link dropdown-toggle active'
                : 'nav-link dropdown-toggle'
            }
            data-toggle="dropdown"
          >
            タックルボックス
            <b className="caret" />
          </a>
          <ul className="dropdown-menu ">
            <li>
              <a
                href={BuildSubDomainURL('tacklebox')}
                className="dropdown-item"
              >
                タックルボックス
              </a>
            </li>
            <li>
              <a
                href={BuildSubDomainURL('tacklebox', 'favorites')}
                className="dropdown-item"
              >
                お気に入り
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a
            href="/magazines"
            className={active === 'magazines' ? 'nav-link active' : 'nav-link'}
          >
            マガジン
          </a>
        </li>
        {userName !== null && (
          <li className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle profile"
              data-toggle="dropdown"
            >
              {userName}
              <b className="caret" />
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a href="/my" className="dropdown-item">
                  自分のプロフィール
                </a>
              </li>
              <li>
                <a
                  href={BuildSubDomainURL('tacklebox', 'favorites')}
                  className="dropdown-item"
                >
                  お気に入り（タックルボックス）
                </a>
              </li>
              <li>
                <a href="/my/followings" className="dropdown-item">
                  フォロー中のユーザー
                </a>
              </li>
              <li>
                <a href="/my/users/blocks" className="dropdown-item">
                  ブロック中のユーザー
                </a>
              </li>
              <li>
                <a href="/my/areas" className="dropdown-item">
                  お気に入りのエリア
                </a>
              </li>
              <li>
                <a href="/my/fields" className="dropdown-item">
                  フィールド
                </a>
              </li>
              <li>
                <a href="/my/rods" className="dropdown-item">
                  ロッド
                </a>
              </li>
              <li>
                <a href="/my/reels" className="dropdown-item">
                  リール
                </a>
              </li>
              <li>
                <a href="/my/lures" className="dropdown-item">
                  ルアー
                </a>
              </li>
              <li>
                <a href="/my/product_reviews" className="dropdown-item">
                  レビュー
                </a>
              </li>
              <li>
                <a href="/users/sign_out" className="dropdown-item">
                  ログアウト
                </a>
              </li>
            </ul>
          </li>
        )}
      </ul>
    );
  }
}
