// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';

type Props = {
  area: Area,
  active: boolean,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
};

const K_WIDTH = 40;
const K_HEIGHT = 40;

export default class AreaMarker extends React.Component<Props> {
  render() {
    const { area, active } = this.props;

    return (
      <div
        className="marker"
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {active && (
          <div className="balloon">
            <a href={`/areas/${area.id}`} className="media">
              <div className="pull-left">
                {area.latest_result_square_photo_url && (
                  <img
                    src={area.latest_result_square_photo_url}
                    width={40}
                    height={40}
                  />
                )}
              </div>
              <div className="media-body">
                <h4 className="media-heading">{area.name}</h4>
                <div className="text-muted row">
                  {area.summed_children_results_count != null && (
                    <div className="col-5">
                      <i className="icon-app f-17 mr-5" />
                      {area.summed_children_results_count}
                    </div>
                  )}
                  {area.latest_caught_at != null && (
                    <div className="col-7 text-right">
                      <i className="fa fa-clock-o fa-fw mr-5" />
                      {area.latest_caught_at.fromNow(true)}前
                    </div>
                  )}
                </div>
              </div>
            </a>
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            width: K_WIDTH,
            height: K_HEIGHT,
            left: -K_WIDTH / 2,
            top: -K_HEIGHT / 2,
            border: '5px solid #3498db',
            borderRadius: K_HEIGHT,
          }}
        >
          {area.latest_result_square_photo_url && (
            <img
              src={area.latest_result_square_photo_url}
              width={K_WIDTH - 10}
              height={K_WIDTH - 10}
              style={{
                top: -5,
                left: -5,
                borderRadius: (K_WIDTH - 10) / 2,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
